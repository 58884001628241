import * as React from "react";
import Navbar from "./HakwareNav";
import Footer from "./Footer";

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout(props: LayoutProps) {
  return (
    <>
      <div className="layout">
        <Navbar />
        {props.children}
      </div>

      <Footer />
    </>
  );
}
