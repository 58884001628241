import React from "react";
import { navigate } from "gatsby";
import "./hero.scss";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import laptop from "../images/laptop.webp";

type HeroTypes = {
  title: string;
  subtitle: string;
  id: string;
  scrollTo: string;
  button: string;
};

const Hero = ({ title, subtitle, button }: HeroTypes) => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleButtonClick = () => {
    const currentPath =
      typeof window !== "undefined" ? window.location.pathname : "";

    if (currentPath.includes("/hakware")) {
      // Scroll to the bottom of the page
      scrollToBottom();
    } else if (currentPath.includes("/thank-you-hakware")) {
      // Navigate back to the home page
      navigate("/");
    }
  };

  return (
    <div className="wrapper" id="hero-bkg">
      <div className="outer">
        <div className="wrapper-hero grid col-2">
          <div id="hero" className="flex col as jc">
            <div className="contact-svg" />
            <ScrollAnimation animateIn="animate__fadeInLeft" duration={1}>
              {<h1>{title}</h1>}
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <h4 className="hero-tagline">{subtitle}</h4>
            </ScrollAnimation>

            <div className="flex row gap-1 ac jc" id="hero-buttons">
              <ScrollAnimation animateIn="animate__fadeInLeft" duration={1}>
                <button
                  style={{
                    border: "none",
                    cursor: "pointer",
                    padding: "20px",
                    backgroundColor: "#B91B20",
                    fontWeight: "bold",
                    fontSize: "var(--medium)",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  className="shaker"
                  onClick={handleButtonClick}
                >
                  {button}
                </button>
              </ScrollAnimation>
            </div>
          </div>
          <div className="laptop laptop-float">
            <img src={laptop} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
