import { Link } from "gatsby";
import React, { useState } from "react";

//Style Sheets
import "../styles/hakwareNav.scss";
import "../styles/hakware.scss";

//Media
import logo from "../images/strategix-logo.svg";
import hamburger from "../images/hamburger.png";
import hackware from "../images/Hakware_logo.webp";
// import { img } from 'gatsby-plugin-image';

export default function Navbar() {
  const [showServices, setShowServices] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const [mobileMenu, setMobileMenu] = useState(false);

  const props = {
    menuClass: !mobileMenu ? "mobile-menu" : "mobile-menu open",
  };

  return (
    <>
      <nav>
        <div className="nav-logo">
          <Link to="/">
            <img
              src={logo}
              width={200}
              height={100}
              alt="strategix technology solutions logo"
              className="resp-image"
            />
          </Link>
        </div>
        <div className="nav-menu">
          <div className="nav-menu-inner">
            <img
              alt="hamburger menu"
              src={hamburger}
              className="hamburger-icon"
              onClick={() => setMobileMenu(true)}
              style={{
                cursor: "pointer",
              }}
            />
            <div className="desktop-menu-hakware" style={{ zIndex: "9999" }}>
              <div className="flex row ac jc hakware-div">
                {" "}
                <h2 style={{ paddingRight: "20px" }}>Powered by</h2>
                <img src={hackware} alt="hackware-logo" />
              </div>
            </div>
          </div>
        </div>
        <div className={props.menuClass}>
          <div className="mobile-menu-close">
            <span
              onClick={() => setMobileMenu(false)}
              style={{
                color: "var(--white)",
                padding: "20px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </span>
          </div>
          <Link to="/">
            <h2>Home</h2>
          </Link>
          <Link to="/about">
            <h2>About Us</h2>
          </Link>
          <h2
            onClick={() => setShowServices(!showServices)}
            style={{
              cursor: "pointer",
            }}
          >
            Solutions & Services
          </h2>
          {showServices ? (
            <div className="service-mobile-menu">
              <Link to="/professional-services">
                <h3>▶ Professional Services</h3>
              </Link>
              <Link to="/managed-services">
                <h3>▶ Managed Services</h3>
              </Link>
              <Link to="/cloud-services">
                <h3>▶ Cloud Services</h3>
              </Link>
              {/* <Link to="#">
                <h3>▶ Products</h3>
              </Link> */}
            </div>
          ) : (
            <></>
          )}
        </div>
      </nav>
    </>
  );
}
