import React, { useState } from "react";
import Layout from "../components/HakwareLayout";
import Hero from "../components/hero";
import "../styles/hakware.scss";

const HakwareThankYou = () => {
  return (
    <Layout>
      <section id="hakware">
        <Hero
          title="Thank You!"
          subtitle="An email has been sent to you containing your detailed report."
          id="thank-you"
          scrollTo=""
          button="Back to home page"
        />
      </section>
    </Layout>
  );
};

export default HakwareThankYou;
